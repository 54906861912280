import type { VNode } from 'preact';
import { classes } from '../stylesheet.js';
import { DEFAULT_STYLES, make_stylesheet, DEFAULT_STYLEHSEET, BLACK } from '#figma';
import { capitalize, getTranslation, px2vh, px2vw, translate } from '#utils';
import mocks from '#mocks';
import type { Components, Layouts } from '../types.js';
import { Children, useEffect, useLayoutEffect, useRef, useState, type FC } from 'react';
import { printPrice } from '../utils.js';

const DEFAULT_PRODUCT_URL =
	'data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%22%20height%3D%22100%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22%23576575%22%20fill-opacity%3D%220.3%22%2F%3E%3C%2Fsvg%3E';

const default_list: Components.List = ({ id, children, style }) => (
	<div id={id} style={{ ...classes('nowrap', 'inline-flex'), whiteSpace: 'nowrap', ...style }}>
		{children}
	</div>
);
const default_link: Components.Link = ({ to, children }) => <div data-to={to}>{children}</div>;
const default_navigable: Components.Navigable = ({ children }) => <div>{children}</div>;

const TopBar: FC<
	Omit<Parameters<Layouts.Subcategories>[0], 'injected'> & {
		injected: {
			Link: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['Link']>;
			List: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['List']>;
		};
	}
> = ({ data, templating, injected }) => {
	const stylesheet = make_stylesheet(templating.css.products);
	let cartProducts = data?.cart?.reduce((acc, cur) => acc + cur.quantity, 0) ?? 0;
	return (
		<div
			data-highlight="section-1"
			style={{
				...DEFAULT_STYLEHSEET.products.default('section1'),
				display: 'table-cell',
				position: 'relative',
				width: '100vw',
				boxShadow: '0px 4px 16px 0px rgba(149, 151, 154, 0.25)',
				height: `${px2vh(277)}vh`,
				...stylesheet.default('section1'),
			}}
		>
			<injected.Link to={`/roomshops/${data.channel.token}/cart`}>
				<div
					style={{
						...classes('absolute'),
						height: `${px2vh(29 + 4 + 16)}vh`,
						fontSize: `${px2vw(24)}vw`,
						lineHeight: `calc(${px2vh(29 + 4 + 16)}vh - ${px2vw(7)}vw)`,
						borderRadius: `${px2vh(4)}vh`,
						padding: `${px2vh(1)}vh ${px2vw(8 + 4)}vw`,
						right: `${px2vw(72)}vw`,
						borderColor: '#D3DAE1',
						borderWidth: '1px',
						borderStyle: 'solid',
						top: `${px2vh(48)}vh`,
						display: 'inline-table',
						backgroundColor: 'white',
						...stylesheet.default('buttons'),
					}}
					onKeyDown={() => {
						sessionStorage.setItem('stepsBack', '3');
					}}
					onMouseDown={() => {
						sessionStorage.setItem('stepsBack', '3');
					}}
					className={'navigable sales-buttons'}
					id={'cart-button'}
				>
					<span className="icon icon-cart" style={{ verticalAlign: 'middle', lineHeight: 1.3 }} />
					&nbsp;{capitalize(translate(templating.texts, 'cart'))} [ {cartProducts} ]
				</div>
			</injected.Link>

			<div
				className="title"
				style={{
					...classes('absolute'),
					left: `${px2vw(72)}vw`,
					fontSize: `${px2vw(36)}vw`,
					top: `${px2vh(72)}vh`,
				}}
			>
				{data.channel.name}
			</div>

			<div
				style={{
					marginLeft: `${px2vw(72)}vw`,
					fontSize: `${px2vw(24)}vw`,
					top: `${px2vh(134)}vh`,
					position: 'absolute',
				}}
			>
				{data.channel.customFields.allow_scheduled_delivery &&
				data.channel.customFields.delivery_schedule &&
				data.channel.customFields.delivery_schedule !== '"figma_string"' ? (
					<div>
						<div style={{ ...classes('inline-block', 'relative') }}>
							{translate(templating.texts, 'Delivery hours')}
						</div>
						<div
							style={{
								...classes('inline-block', 'relative'),
								marginLeft: `${px2vw(21)}vw`,
								whiteSpace: 'pre',
							}}
						>
							{JSON.parse(data.channel.customFields.delivery_schedule).map(
								(x: { startTime: string; endTime: string }) => `${x.startTime} - ${x.endTime}\t`
							)}
						</div>
					</div>
				) : data.channel.customFields.delivery_schedule === '"figma_string"' ? (
					translate(templating.texts, 'Delivery time')
				) : (
					translate(templating.texts, 'Delivery 24 hours')
				)}
			</div>

			<div
				style={{
					top: `calc(${px2vh(189)}vh)`,
					left: `${px2vw(72)}vw`,
					width: `calc(100vw - ${px2vw(72)}vw)`,
					position: 'absolute',
				}}
			>
				<injected.List id="categories">
					{data.categories?.length > 1 &&
						data.categories.map((x, i) => (
							<injected.Link
								key={x.id}
								to={`/roomshops/${encodeURIComponent(data.channel.token)}/categories/${x.id}`}
								replace={true}
							>
								<div
									data-highlight="buttons"
									tabIndex={0}
									className={'navigable sales-buttons'}
									id={`category-${x.id}`}
									style={{
										...DEFAULT_STYLEHSEET.products.default('buttons'),
										...(data.activeCategory === x.id
											? DEFAULT_STYLEHSEET.products.active('buttons')
											: {}),
										...classes('relative', 'inline-block'),
										borderRadius: `${px2vw(4)}vw`,
										height: `${px2vh(29 + 10 + 16)}vh`,
										marginLeft: `${px2vw(i === 0 ? 0 : 16)}vw`,
										fontSize: `${px2vw(24)}vw`,
										lineHeight: 1.2,
										padding: `${px2vh(1)}vh ${px2vw(18)}vw ${px2vh(1)}vh ${px2vw(18)}vw`,
										borderColor: '#D3DAE1',
										borderWidth: '1px',
										borderStyle: 'solid',
										display: 'inline-table',
										...stylesheet.default('buttons'),
										...(data.activeCategory === x.id ? stylesheet.active('buttons') : {}),
									}}
								>
									<div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
										{getTranslation(
											x.translations,
											templating.languageCode,
											templating.projectLanguageCode
										)}
									</div>
								</div>
							</injected.Link>
						))}
				</injected.List>
			</div>
		</div>
	);
};

const Tag: FC<{ name: string; index: number }> = ({ name }) => (
	<div
		key={name}
		style={{
			...classes('text-center', 'inline-block'),
			padding: `${px2vw(8)}vw ${px2vh(24)}vh`,
			fontSize: `${px2vw(20)}vw`,
			backgroundColor: 'rgba(255, 255, 255, 0.80)',
			color: BLACK,
			marginRight: `${px2vw(10)}vw`,
			float: 'right',
			marginTop: `${px2vw(10)}vw`,
			borderRadius: `${px2vw(4)}vw`,
		}}
	>
		{name}
	</div>
);

const SubSubcategory: FC<{
	subsubcategory: Parameters<Layouts.Subcategories>[0]['data']['subcategories'][number];
	data: Parameters<Layouts.Subcategories>[0]['data'];
	templating: Parameters<Layouts.Subcategories>[0]['templating'];
	injected: {
		Link: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['Link']>;
		List: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['List']>;
		BackgroundImage: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['BackgroundImage']>;
		Navigable: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['Navigable']>;
	};
	signals: any;
}> = ({ subsubcategory, injected, data, signals, templating }) => {
	const stylesheet = make_stylesheet(templating.css.products ?? DEFAULT_STYLES.products);
	return (
		<injected.List id={`subcategory-${subsubcategory.id}`}>
			{subsubcategory.productVariants.items.slice(0, 8).map((x, i) => {
				const itemId = encodeURIComponent(`${subsubcategory.id}-item-${x.product.id}-${x.id}`);
				const isFocused = signals?.focus.value.current === itemId;
				const subsubcategories = data.subcategories.map((x) => x.children).flat(1);
				const subcategoryIndex = subsubcategories.findIndex((x) => x.id === subsubcategory.id);
				const bgImage =
					x?.featuredAsset?.source ||
					x?.assets?.[0]?.source ||
					x?.product?.featuredAsset?.source ||
					x?.product?.assets?.[0]?.source ||
					DEFAULT_PRODUCT_URL;
				const onFocus = (e) => {
					if (subcategoryIndex === -1 || subcategoryIndex === 0) {
						e.target.parentElement?.parentElement?.scrollTo({ top: 0 });
						document.getElementById('HL')?.scrollTo({ top: 0 });
					} else if (subcategoryIndex === subsubcategories.length - 1) {
						document.getElementById('HL')?.scrollTo({ top: Infinity });
					}
				};
				return (
					<injected.Link
						key={x.id}
						to={`/roomshops/${encodeURIComponent(data.channel.token)}/products/${x.product.id}/variant/${
							x.id
						}`}
					>
						<div
							onFocus={onFocus}
							data-scroll-on-focus={true}
							data-highlight="items"
							className={'navigable btn-focusOutline category-product'}
							id={itemId}
							style={{
								...DEFAULT_STYLEHSEET.products.default('items'),
								...classes('relative', 'inline-block', 'object-cover'),
								...stylesheet.default('items'),
								overflow: 'hidden',
								borderRadius: `${px2vw(4)}vw`,
								width: `${px2vw(328)}vw`,
								height: `${px2vh(264)}vh`,
								margin: `auto ${px2vw(i === 0 ? 72 : 32)}vw`,
								marginRight: `${px2vw(32)}vw`,
								verticalAlign: 'middle',
							}}
						>
							<injected.BackgroundImage src={bgImage}>
								<div ref={useRef(null)} style={{ ...classes('absolute', 'w-full', 'h-full') }}></div>
							</injected.BackgroundImage>
							{!isFocused ? (
								<div
									style={{ width: `${px2vw(240)}vw`, float: 'right', position: 'absolute', right: 0 }}
								>
									{x?.product?.tags?.map((x, i) => (
										<Tag key={x} index={i} name={x} />
									))}
								</div>
							) : null}
							<div
								style={{
									...classes('absolute', 'w-full'),
									display: 'table',
									borderBottomRightRadius: `${px2vw(4)}vw`,
									borderBottomLeftRadius: `${px2vw(4)}vw`,
									borderTopRightRadius: `${px2vw(isFocused ? 4 : 0)}vw`,
									borderTopLeftRadius: `${px2vw(isFocused ? 4 : 0)}vw`,
									height: `${isFocused ? '100%' : `${px2vh(99)}vh`}`,
									backgroundColor: 'rgba(255, 255, 255, 0.80)',
									color: BLACK,
									bottom: 0,
									zIndex: 20,
								}}
							>
								<div
									style={{
										display: isFocused ? 'table' : '',
										backgroundColor: 'rgba(255, 255, 255, 0.80)',
										position: 'absolute',
										height: isFocused ? '100%' : '9vh',
										lineHeight: isFocused ? '' : '9vh',
										width: '100%',
										textAlign: 'center',
										paddingLeft: `7%`,
										paddingRight: `7%`,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: isFocused ? 'normal' : 'nowrap',
										fontSize: `${px2vw(30)}vw`,
									}}
								>
									<div
										style={{
											display: isFocused ? 'table-cell' : 'block',
											verticalAlign: 'middle',
										}}
									>
										<div
											style={{
												textAlign: 'center',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: isFocused ? 'normal' : 'nowrap',
												fontWeight: 700,
												display: isFocused ? '-webkit-box' : '',
												webkitLineClamp: isFocused ? 4 : 1,
												webkitBoxOrient: 'vertical',
												verticalAlign: 'bottom',
											}}
										>
											{x.product.translations &&
												getTranslation(
													x.product.translations,
													templating.languageCode,
													templating.projectLanguageCode
												)}
										</div>
										{isFocused ? (
											<div
												style={{
													fontSize: `${px2vw(30)}vw`,
													fontWeight: 500,
													marginTop: '1vh',
												}}
											>
												{printPrice(
													(sessionStorage.getItem('pricesIncludeTax') === 'true'
														? x.priceWithTax
														: x.price) / 100,
													data.channel.currencyCode
												)}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</injected.Link>
				);
			})}
			{subsubcategory.productVariants.items.length > 8 ? (
				<injected.Link
					to={`/roomshops/${encodeURIComponent(data.channel.token)}/categories/${
						subsubcategory.breadcrumbs.at(-1)!.id
					}/subcategory/${subsubcategory.id}`}
					replace={true}
				>
					<div
						data-scroll-on-focus={true}
						data-highlight="items"
						className={'navigable btn-focusOutline'}
						id={`${subsubcategory.id}-view-more`}
						style={{
							...DEFAULT_STYLEHSEET.products.default('items'),
							...classes('relative', 'inline-block', 'object-cover', 'text-center', 'relative'),
							...stylesheet.default('items'),
							backgroundColor: '#F5F6F8',
							borderRadius: `${px2vw(4)}vw`,
							width: `${px2vw(328)}vw`,
							height: `${px2vh(264)}vh`,
							display: 'inline-block',
							margin: `auto ${px2vw(32)}vw`,
							marginRight: `${px2vw(32)}vw`,
							verticalAlign: 'middle',
						}}
					>
						<div
							style={{
								display: 'table-cell',
								width: `${px2vw(328)}vw`,
								height: `${px2vh(264)}vh`,
								verticalAlign: 'middle',
							}}
						>
							{capitalize(translate(templating.texts, 'View more'))}
						</div>
					</div>
				</injected.Link>
			) : null}
		</injected.List>
	);
};

const NavigableBody: FC<{
	data: Parameters<Layouts.Subcategories>[0]['data'];
	templating: Parameters<Layouts.Subcategories>[0]['templating'];
	subcat: Parameters<Layouts.Subcategories>[0]['data']['subcategories'][number];
	subsubcat: Parameters<Layouts.Subcategories>[0]['data']['subcategories'][number][number];
	injected: {
		Link: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['Link']>;
		List: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['List']>;
		BackgroundImage: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['BackgroundImage']>;
		Navigable: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['Navigable']>;
	};
	signals: any;
}> = ({ subcat, data, templating, subsubcat, injected, signals }) => {
	return (
		<div id={'subsubcat-' + subsubcat.id} style={{ ...classes('relative') }}>
			<div
				data-highlight="section-3"
				className="title"
				style={{
					...classes('relative'),
					display: 'table-cell',
					width: '100vw',
					verticalAlign: 'middle',
					fontSize: `${px2vw(32)}vw`,
					paddingLeft: `${px2vw(72)}vw`,
					height: `${px2vh(80)}vh`,
				}}
			>
				{getTranslation(subsubcat.translations, templating.languageCode, templating.projectLanguageCode)}
			</div>
			<SubSubcategory
				subsubcategory={{ ...subsubcat, children: [] }}
				data={data}
				templating={templating}
				injected={injected}
				signals={signals}
			/>
		</div>
	);
};

const Subcategory: FC<{
	data: Parameters<Layouts.Subcategories>[0]['data'];
	templating: Parameters<Layouts.Subcategories>[0]['templating'];
	subcat: Parameters<Layouts.Subcategories>[0]['data']['subcategories'][number];
	injected: {
		Link: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['Link']>;
		List: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['List']>;
		BackgroundImage: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['BackgroundImage']>;
		Navigable: NonNullable<NonNullable<Parameters<Layouts.Subcategories>[0]['injected']>['Navigable']>;
	};
	signals: any;
}> = ({ subcat, data, templating, injected, signals }) => {
	const Navigable = injected?.Navigable ?? default_navigable;
	const hasSubSubCategory = subcat.children?.length > 0 && subcat.breadcrumbs.length === 3;
	const filtered = data.subcategories.filter((x) => x.productVariants.items.length > 0);
	const nextSubcategory = filtered[filtered.findIndex((x) => x.id === subcat.id) + 1];
	const nextSubcategoryChildren = nextSubcategory?.children?.find((x) => x.productVariants.items.length > 0);
	const prevSubcategory = filtered[filtered.findIndex((x) => x.id === subcat.id) - 1];
	const prevSubcategoryChildren = prevSubcategory?.children?.findLast((x) => x.productVariants.items.length > 0);
	const navMap = {
		up: prevSubcategoryChildren
			? encodeURIComponent(
					`${prevSubcategoryChildren?.id}-item-${
						prevSubcategoryChildren!.productVariants.items[0]!.product.id
					}-${prevSubcategoryChildren!.productVariants.items[0]!.id}`
			  )
			: prevSubcategory
			? encodeURIComponent(
					`${prevSubcategory!.id}-item-${prevSubcategory!.productVariants.items[0]!.product.id}-${
						prevSubcategory!.productVariants.items[0]!.id
					}`
			  )
			: undefined,
		down: nextSubcategoryChildren
			? encodeURIComponent(
					`${nextSubcategoryChildren?.id}-item-${
						nextSubcategoryChildren!.productVariants.items[0]!.product.id
					}-${nextSubcategoryChildren!.productVariants.items[0]!.id}`
			  )
			: nextSubcategory
			? encodeURIComponent(
					`${nextSubcategory!.id}-item-${nextSubcategory!.productVariants.items[0]!.product.id}-${
						nextSubcategory!.productVariants.items[0]!.id
					}`
			  )
			: undefined,
	};

	return (
		<>
			{hasSubSubCategory ? (
				subcat.children
					?.filter((x) => x.productVariants.items.length > 0)
					.map((subsubcat, i, arr) => {
						const down = arr[i + 1]
							? encodeURIComponent(
									`${arr[i + 1]!.id}-item-${arr[i + 1]!.productVariants.items[0]!.product.id}-${
										arr[i + 1]!.productVariants.items[0]!.id
									}`
							  )
							: undefined;
						const up = arr[i - 1]
							? encodeURIComponent(
									`${arr[i - 1]!.id}-item-${arr[i - 1]!.productVariants.items[0]!.product.id}-${
										arr[i - 1]!.productVariants.items[0]!.id
									}`
							  )
							: undefined;
						return (
							<Navigable
								key={subsubcat.id}
								navMap={{
									down: down ?? navMap.down,
									up: up ?? navMap.up,
								}}
							>
								<NavigableBody
									subsubcat={subsubcat}
									data={data}
									templating={templating}
									subcat={subcat}
									injected={injected}
									signals={signals}
								/>
							</Navigable>
						);
					})
			) : (
				<Navigable navMap={navMap}>
					<SubSubcategory
						subsubcategory={subcat}
						data={data}
						templating={templating}
						injected={injected}
						signals={signals}
					/>
				</Navigable>
			)}
		</>
	);
};
const default_background_image: FC<{ src: string }> = ({ src, children }) => {
	const [targetElement, setTargetElement] = useState<VNode | null>(null);
	useEffect(() => {
		setTargetElement(Children.only(children) as VNode<any>);
	}, []);
	if (targetElement?.ref && src) {
		if (src.startsWith('rgb(') || src.startsWith('rgba(') || src.startsWith('#')) {
			//@ts-ignore
			targetElement.ref.current.style.background = src;
		} else if (src.startsWith('data:')) {
			//@ts-ignore
			targetElement.ref.current.style.backgroundImage = `url("${src}")`;
		} else {
			//@ts-ignore
			targetElement.ref.current.style.backgroundImage = `url("${src}?w=${targetElement.ref.current.clientWidth}&h=${targetElement.ref.current.clientHeight}")`;
		}
		//@ts-ignore
		targetElement.ref.current.style.backgroundPosition = '50% 50%';
		//@ts-ignore
		targetElement.ref.current.style.backgroundSize = 'cover';
	}
	return <>{children}</>;
};

export default (({ data, templating, injected, signals }) => {
	if (!data) data = mocks.subcategories(templating.languageCode);
	const Link = injected?.Link ?? default_link;
	const List = injected?.List ?? default_list;
	const Navigable = injected?.Navigable ?? default_navigable;
	const BackgroundImage = injected?.BackgroundImage ?? default_background_image;
	const stylesheet = make_stylesheet(templating.css.products ?? DEFAULT_STYLES.products);

	const focusFirst = () => {
		const products = document.getElementsByClassName('category-product');
		signals?.focus.value.replace(
			products?.[0] ? products[0].id : data.categories[0]?.id ? `category-${data.categories[0]?.id}` : 'noFocus',
			{ preventScroll: false }
		);
	};

	const subCategories = data.subcategories.filter((x) => x.productVariants.items.length > 0);
	const singleSubCategory = subCategories?.length === 1 && data.categories.length > 1;

	useLayoutEffect(() => {
		const focusedItem =
			document.getElementById(signals?.focus.value?.current) &&
			signals?.focus.value?.current?.indexOf('-item-') > -1;
		focusedItem && signals?.focus.value.replace(signals?.focus.value?.current);
		if (!focusedItem) {
			const timeout = setTimeout(focusFirst, 200);
			return () => clearTimeout(timeout);
		}
	}, [window.location.pathname]);

	return (
		<>
			<TopBar templating={templating} data={data} injected={{ ...injected, Link, List }} />
			<List
				id={'HL'}
				style={{
					overflowY: 'scroll',
					display: 'block',
					width: '100vw',
					height: `calc(100vh - ${px2vh(277)}vh)`,
				}}
			>
				{subCategories.map((x, i, arr) => {
					let down = arr[i + 1]
						? encodeURIComponent(
								`${arr[i + 1]!.id}-item-${arr[i + 1]!.productVariants.items[0]!.product.id}-${
									arr[i + 1]!.productVariants.items[0]!.id
								}`
						  )
						: undefined;
					let up = arr[i - 1]
						? encodeURIComponent(
								`${arr[i - 1]!.id}-item-${arr[i - 1]!.productVariants.items[0]!.product.id}-${
									arr[i - 1]!.productVariants.items[0]!.id
								}`
						  )
						: undefined;
					if (arr[i + 1] && arr[i + 1]!.children?.length > 0) {
						down = arr[i + 1]!.children[0]
							? encodeURIComponent(
									`${arr[i + 1]!.children[0]?.id}-item-${
										arr[i + 1]!.children[0]!.productVariants.items[0]?.product?.id
									}-${arr[i + 1]!.children[0]!.productVariants.items[0]?.id}`
							  )
							: undefined;
					}
					if (arr[i - 1] && arr[i - 1]!.children?.length > 0) {
						const childrenToFocus = arr[i - 1]!.children.toReversed().find(
							(children) => children!.productVariants!.items.length > 0
						);
						const lastItem = childrenToFocus!.productVariants!.items.length - 1;
						up = childrenToFocus
							? encodeURIComponent(
									`${childrenToFocus.id}-item-${
										childrenToFocus!.productVariants.items[lastItem]?.product?.id
									}-${childrenToFocus!.productVariants?.items[lastItem]?.id}`
							  )
							: undefined;
					}
					return (
						<Navigable
							key={x.id}
							navMap={{
								down: down,
								up: up,
							}}
						>
							<div
								id={x.id}
								style={{ ...classes('relative'), ...stylesheet.default('section3') }}
								data-highlight="section-3"
							>
								<div
									className="title"
									data-highlight="section-2"
									style={{
										...classes('relative'),
										display: 'table-cell',
										width: '100vw',
										verticalAlign: 'middle',
										fontSize: `${px2vw(32)}vw`,
										paddingLeft: `${px2vw(72)}vw`,
										height: `${px2vh(singleSubCategory ? 40 : 80)}vh`,
										...stylesheet.default(singleSubCategory ? '' : 'section2'),
									}}
								>
									{!singleSubCategory &&
										getTranslation(
											x.translations,
											templating.languageCode,
											templating.projectLanguageCode
										)}
								</div>
								<div
									style={{
										whiteSpace: 'nowrap',
										overflowX: 'scroll',
										paddingTop: `${px2vh(16)}vh`,
										paddingBottom: `${px2vh(16)}vh`,
									}}
								>
									<Subcategory
										subcat={x}
										templating={templating}
										data={data}
										injected={{ ...injected, Link, List, BackgroundImage, Navigable }}
										signals={signals}
									/>
								</div>
							</div>
						</Navigable>
					);
				})}
			</List>
		</>
	);
}) as Layouts.Subcategories;
