import { match } from 'ts-pattern';
import { classes } from '../../stylesheet.js';
import calculator from '../cart/calculators/index.js';
import Allergen from './allergens/index.js';
import Carousel from './Carousel.js';
import Extra from './extras/index.js';
import { NumberInput as DefaultNumberInput } from '#inputs';
import { DEFAULT_STYLES, DEFAULT_STYLEHSEET, make_stylesheet } from '#figma';
import { capitalize, getTranslation, px2vh, px2vw, translate } from '#utils';
import mocks from '#mocks';
import type { FC, Layouts, CSSProperties, Components, Extras } from '../../types.js';
import type { ComponentChildren } from 'preact';
import { useEffect } from 'react';
import Configurator from './extras/configurator.js';
import { signal } from '@preact/signals';
import { getCartNumProducts, printPrice } from '../../utils.js';

const TopBar: FC<{
	data: Parameters<Layouts.Details>[0]['data'];
	templating: Parameters<Layouts.Details>[0]['templating'];
	stylesheet: ReturnType<typeof make_stylesheet<'productDetails'>>;
	injected: NonNullable<Pick<NonNullable<Parameters<Layouts.Details>[0]['injected']>, 'Link' | 'Navigable'>>;
}> = ({ data, stylesheet, injected, templating }) => {
	const Link = injected.Link as NonNullable<typeof injected.Link>;

	const firstExtraId = data.product.extras?.[0]?.id;
	const cartButtonNavMap = {
		down: firstExtraId
			? `extra-${firstExtraId}`
			: data.product.description?.trim()
			? 'description'
			: data.product.allergens.length
			? 'allergens'
			: 'quantity',
	};
	const cartProducts = getCartNumProducts(data.signals.cart.value?.[data.shop.token]);

	return (
		<div
			data-highlight="section-1"
			style={{
				...DEFAULT_STYLEHSEET.roomshops.default('section1'),
				position: 'relative',
				display: 'table-cell',
				width: '100vw',
				boxShadow: '4px 0px 16px #95979A40',
				height: `${px2vh(152)}vh`,
				...stylesheet.default('section1'),
			}}
		>
			<div
				className="title"
				style={{
					...classes('bold'),
					fontSize: `${px2vw(36)}vw`,
					marginLeft: `${px2vw(70)}vw`,
					marginTop: `${px2vh(60)}vh`,
					marginBottom: `${px2vh(60)}vh`,
				}}
			>
				{data.shop.name}
			</div>
			<injected.Navigable navMap={cartButtonNavMap}>
				<Link to={`/roomshops/${data.shop.token}/cart`}>
					<div
						style={{
							...classes('absolute'),
							height: `${px2vh(29 + 4 + 16)}vh`,
							fontSize: `${px2vw(24)}vw`,
							lineHeight: `calc(${px2vh(29 + 4 + 16)}vh - ${px2vw(7)}vw)`,
							borderRadius: `${px2vh(4)}vh`,
							padding: `${px2vh(1)}vh ${px2vw(8 + 4)}vw`,
							right: `${px2vw(72)}vw`,
							borderColor: '#D3DAE1',
							borderWidth: '1px',
							borderStyle: 'solid',
							display: 'inline-table',
							top: `${px2vh(48)}vh`,
							backgroundColor: 'white',
							...stylesheet.default('buttons'),
						}}
						onKeyDown={() => {
							sessionStorage.setItem('stepsBack', '4');
						}}
						onMouseDown={() => {
							sessionStorage.setItem('stepsBack', '4');
						}}
						className={'navigable sales-buttons'}
						id={'cart-button'}
					>
						<span className="icon icon-cart" style={{ verticalAlign: 'middle', lineHeight: 1.3 }} />
						&nbsp;{capitalize(translate(templating.texts, 'cart'))} [ {cartProducts} ]
					</div>
				</Link>
			</injected.Navigable>
		</div>
	);
};

const Tag: FC<{ name: string; index: number }> = ({ name, index }) => (
	<div
		key={name}
		style={{
			...classes('text-center', 'inline-block'),
			padding: `${px2vw(8)}vw ${px2vh(24)}vh`,
			height: `${px2vh(48)}vh`,
			fontSize: `${px2vw(24)}vw`,
			backgroundColor: '#2E3843',
			color: '#E9EDF0',
			marginLeft: `${index === 0 ? 0 : px2vw(24)}vw`,
			border: '1px solid',
			borderColor: '#E9EDF0',
		}}
	>
		{name}
	</div>
);

const ExtraComponent: FC<{
	extra: any;
	templating: Parameters<Layouts.Details>[0]['templating'];
	idList: (number | string)[];
	injected: {
		configurator: Extras.Configurator;
		Modal: Components.Modal;
		VerticalList: Components.List;
		Navigable: Components.Navigable;
	};
	signals: NonNullable<Parameters<Layouts.Details>[0]['signals']>;
	options: {
		currency_code: string;
	};
	style: CSSProperties;
}> = ({ extra, options, style, idList, injected, signals, templating }) => {
	return (
		<Extra
			data={{ extra, options }}
			idList={idList}
			signals={signals}
			injected={injected}
			style={style}
			templating={templating}
		/>
	);
};
const Footer: FC<{
	data: Parameters<Layouts.Details>[0]['data'];
	signals: Parameters<Layouts.Details>[0]['signals'];
	templating: Parameters<Layouts.Details>[0]['templating'];
	stylesheet: ReturnType<typeof make_stylesheet<'productDetails'>>;
	injected: Pick<
		NonNullable<Parameters<Layouts.Details>[0]['injected']>,
		'NumberInput' | 'onAddToCart' | 'Navigable'
	>;
	inEdition: Parameters<Layouts.Details>[0]['inEdition'];
}> = ({ data, stylesheet, injected, signals, templating, inEdition }) => {
	const withTaxes = sessionStorage.getItem('pricesIncludeTax') === 'true';
	const total =
		(withTaxes ? data.product.price : data.product.priceWithoutTax) +
		Object.keys(signals.config.value).reduce(
			(acc, extraId) =>
				acc +
				calculator(data.extras[extraId] as any, signals.config.value[extraId] as any)[
					withTaxes ? 'priceWithTax' : 'priceWithoutTax'
				],
			0
		);

	const lastExtraId = data?.product?.extras?.length
		? data.product.extras?.[data?.product?.extras?.length - 1]?.id
		: null;

	return (
		<div
			style={{
				...classes('text-center', 'w-full', 'inline-block', 'relative'),
				height: `${px2vh(240)}vh`,
				marginTop: `${px2vh(40)}vh`,
				boxShadow: '0px -4px 16px 0px #95979A40',

				...stylesheet.default('section1'),
			}}
		>
			<div style={{ ...classes('relative', 'inline-block') }}>
				{lastExtraId ? (
					<injected.Navigable navMap={{ right: `extra-${lastExtraId}` }}>
						{injected.NumberInput}
					</injected.Navigable>
				) : (
					injected.NumberInput
				)}
				<div
					style={{
						...classes('absolute'),
						bottom: `calc(50% - ${px2vw(32) / 2}vw)`,
						left: `calc(50% + ${px2vw(60)}vw)`,
						fontSize: `${px2vw(32)}vw`,
					}}
				>
					{translate(templating.texts, data?.signals?.amount?.value > 1 ? 'units' : 'unit')}
				</div>
			</div>
			<div>
				<injected.Navigable navMap={{ up: 'quantity' }}>
					<div
						id={'AddToCartButton'}
						className={'navigable sales-buttons'}
						style={{
							...classes('text-center', 'rounded'),
							position: 'relative',
							margin: '2vh auto 0 auto',
							width: `${px2vw(600)}vw`,
							height: `${px2vh(56)}vh`,
							paddingTop: `${px2vh(3)}vh`,
							paddingBottom: `${px2vh(8)}vh`,
							boxSizing: 'border-box',
							backgroundColor: 'white',
							borderColor: '#D3DAE1',
							display: 'inline-table',
							borderWidth: '1px',
							borderStyle: 'solid',
							fontSize: `${px2vw(32)}vw`,
							lineHeight: `${px2vh(38.4)}vh`,
							...stylesheet.default('buttons'),
						}}
						onClick={injected.onAddToCart}
					>
						{translate(templating.texts, inEdition ? 'update-for-price' : 'add-for-price')
							.replace('{{amount}}', data.signals.amount?.value?.toString())
							.replace(
								'{{price}}',
								printPrice((total * data.signals.amount.value) / 100, data.product.currencyCode)
							)}
					</div>
				</injected.Navigable>
			</div>
		</div>
	);
};

const Main: FC<{
	data: Parameters<Layouts.Details>[0]['data'];
	templating: Parameters<Layouts.Details>[0]['templating'];
	signals: Parameters<Layouts.Details>[0]['signals'];
	stylesheet: ReturnType<typeof make_stylesheet<'productDetails'>>;
	injected: Pick<
		NonNullable<Parameters<Layouts.Details>[0]['injected']>,
		'Autoscrollable' | 'Navigable' | 'allergensOnClick' | 'configurator' | 'Modal' | 'VerticalList'
	>;
}> = ({ data, stylesheet, injected, signals, templating }) => {
	const allergensNavMap = {
		up: data.product.description && data.product.description.trim() !== '' ? 'description' : 'cart-button',
		down: 'quantity',
	} as const;
	const hasImage = data.product.assets?.length > 0;
	const hasDescription = !!data.product?.description;
	const fullWidth = hasImage || hasDescription;
	const productWidth = fullWidth ? 1264 : 632;
	const hasExtras = data.product.extras?.length > 0;
	const _price =
		sessionStorage.getItem('pricesIncludeTax') === 'true' ? data.product.price : data.product.priceWithoutTax;
	const idList = data.product.extras.map((x) => `extra-${x.id}`);
	const extrasNavMaps = data.product.extras.map((x, i, arr) => {
		return match(i)
			.with(0, () => ({}))
			.with(data.product.extras.length - 1, () => ({
				up: `extra-${data.product.extras[data.product.extras.length - 2].id}`,
			}))
			.otherwise(() => ({
				up: `extra-${data.product.extras[i - 1].id}`,
				down: `extra-${data.product.extras[i + 1].id}`,
			}));
	});
	return (
		<div style={{ width: '100vw', float: 'left', marginTop: `${px2vh(40)}vh` }}>
			<div
				style={{
					width: `${px2vw(productWidth)}vw`,
					height: `${px2vh(608)}vh`,
					float: 'left',
					borderTopRightRadius: fullWidth ? `${px2vw(4)}vw` : 0,
					marginLeft: fullWidth ? 0 : `${px2vw(645)}vw`,
					borderBottomRightRadius: fullWidth ? `${px2vw(4)}vw` : 0,
					position: 'relative',
					...stylesheet.default('section2'),
				}}
			>
				<div
					className="title"
					style={{
						...classes('bold'),
						fontSize: `${px2vw(32)}vw`,
						marginTop: `${px2vh(32)}vh`,
						marginLeft: `${px2vw(hasImage ? 72 : 48)}vw`,
					}}
				>
					{getTranslation(data.product.translations, templating.languageCode, templating.projectLanguageCode)}
				</div>
				<Carousel images={data.product.assets} />
				<div
					style={{ ...classes('relative'), left: `${px2vw(48)}vw`, height: `${px2vh(448)}vh` }}
					ref={(node) => {
						if (node) {
							node.style.setProperty('overflow', 'visible', 'important');
						}
					}}
				>
					<div style={{ fontSize: `${px2vw(32)}vw`, marginTop: '0.5em', fontWeight: 700 }}>
						{printPrice(_price / 100, data.product.currencyCode)}{' '}
						<span style={{ fontSize: `${px2vw(28)}vw`, fontWeight: 400 }}></span>
					</div>
					<injected.Navigable navMap={{ down: data.product.allergens.length ? 'allergens' : 'quantity' }}>
						<injected.Autoscrollable speed={200}>
							<div
								id="description"
								className={
									data.product.description &&
									data.product.description.trim() !== '' &&
									(signals?.focus.value.current != 'quantity' || !hasExtras)
										? 'navigable'
										: ''
								}
								tabIndex={0}
								style={{
									height: `${px2vh(156)}vh`,
									fontSize: `${px2vw(24)}vw`,
									width: `${px2vw(576)}vw`,
									overflowY: 'scroll',
									marginTop: `calc(${px2vh(32)}vh - 0.5em)`,
								}}
							>
								{data.product.description || data.product?.description}
							</div>
						</injected.Autoscrollable>
					</injected.Navigable>
					<div style={{ marginTop: `${px2vh(32)}vh`, marginLeft: `${px2vw(24)}vw` }}>
						{data.product.tags.map((x, i) => (
							<Tag key={x} index={i} name={x} />
						))}
					</div>
					<injected.Navigable navMap={allergensNavMap}>
						<div
							style={{
								width: `32vw`,
								marginTop: `${px2vh(22)}vh`,
								borderRadius: '0.25rem',
								position: 'absolute',
								display: 'table',
								right: '3.7vw',
								height: '14vh',
							}}
							ref={(node) => {
								if (node) {
									node.style.setProperty('overflow', 'visible', 'important');
								}
							}}
						>
							<div
								className={
									data.product.allergens.length &&
									(signals?.focus.value.current != 'quantity' || !hasExtras)
										? 'navigable'
										: ''
								}
								id="allergens"
								onClick={injected.allergensOnClick}
								style={{
									padding: '0.5vw',
									height: 'auto',
									position: 'absolute',
									bottom: 0,
								}}
							>
								{data.product.allergens.map((allergen, i) => (
									<Allergen
										key={allergen.code.toLowerCase()}
										name={allergen.code}
										style={{
											display: 'inline-block',
											marginRight:
												(i > 0 && i % 7 === 0) || i + 1 === data.product.allergens?.length
													? ''
													: '1rem',
										}}
									/>
								))}
							</div>
						</div>
					</injected.Navigable>
				</div>
			</div>
			{hasExtras ? (
				<div
					style={{
						width: `${px2vw(560)}vw`,
						position: 'relative',
						overflowY: 'scroll',
						float: 'right',
						marginRight: `${px2vw(58)}vw`,
						height: `${px2vh(608)}vh`,
						background: 'green',
						borderRadius: `${px2vw(8)}vw`,
						...stylesheet.default('section3'),
					}}
				>
					<div
						className="title"
						style={{
							...classes('bold', 'text-center'),
							fontSize: `${px2vw(32)}vw`,
							marginTop: `${px2vh(32)}vh`,
							marginLeft: `${px2vw(135)}vw`,
							marginRight: `${px2vw(135)}vw`,
							marginBottom: `${px2vh(18)}vh`,
							textTransform: 'capitalize',
						}}
					>
						{translate(templating.texts, 'extras')}
					</div>
					{data.product.extras.map((x, i) => {
						return (
							<injected.Navigable
								scrollIntoView={{ block: i !== 0 ? 'center' : 'start', behavior: 'smooth' }}
								navMap={extrasNavMaps[i]}
							>
								<ExtraComponent
									idList={idList}
									injected={injected as Required<Parameters<Layouts.Details>[0]['injected']>}
									templating={templating}
									signals={signals}
									extra={x}
									options={{ currency_code: data.product.currencyCode }}
									style={{
										...DEFAULT_STYLEHSEET.productDetails.default('items'),
										...stylesheet.default('items'),
										marginBottom: i !== 0 && i !== data.product.extras.length ? undefined : 0,
										padding: '11px',
										boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
									}}
								/>
							</injected.Navigable>
						);
					})}
				</div>
			) : null}
		</div>
	);
};
const navigable = ({ children }: { children: ComponentChildren }) => <>{children}</>;
const default_link: Components.Link = ({ to, children }) => <a data-to={to}>{children}</a>;
const default_modal: Components.Modal = () => <></>;
const default_list: Components.List = ({ children }) => <>{children}</>;

export default (({
	data,
	templating,
	injected,
	signals = { focus: signal({ replace: () => {} }), amount: signal(0), config: signal({}), cart: signal([]) },
	inEdition,
}) => {
	if (!data) data = mocks.details(templating.languageCode);
	const stylesheet = make_stylesheet(templating?.css?.productDetails ?? DEFAULT_STYLES.productDetails);
	const Navigable: Components.Navigable = injected?.Navigable || navigable;
	const Autoscrollable = injected?.Autoscrollable || (navigable as Components.Autoscrollable);
	const Link = injected?.Link || default_link;
	const Modal = injected?.Modal || default_modal;
	const VerticalList = injected?.VerticalList || (default_list as Components.List);
	const configurator =
		injected?.configurator ||
		(new Configurator() as NonNullable<NonNullable<Parameters<Layouts.Details>[0]['injected']>['configurator']>);
	const NumberInput = injected?.NumberInput ?? (
		<DefaultNumberInput
			id={'a'}
			value={1}
			style={{
				...DEFAULT_STYLEHSEET.productDetails.default('buttons'),
				height: `${px2vh(56)}vh`,
				width: `${px2vw(60)}vw`,
				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: `${px2vh(43)}vh`,
				marginBottom: `${px2vh(45)}vh`,
				borderStyle: 'solid',
				borderWidth: '1px',
				...stylesheet.default('buttons'),
			}}
		/>
	);
	const og_addToCart = injected?.onAddToCart ?? (() => {});
	if (injected?.onAddToCart) {
		//Not defined on the manager
		injected.onAddToCart = () => {
			og_addToCart();
			signals.config.value = {};
			injected.configurator.fromJson({});
		};
	}
	useEffect(() => {
		signals?.focus.value.replace(NumberInput.props.id);
	}, []);
	return (
		<div style={{ height: '100vh', overflowY: 'hidden' }}>
			<TopBar data={data} templating={templating} stylesheet={stylesheet} injected={{ Link, Navigable }} />
			<Main
				data={data}
				signals={signals as Parameters<Layouts.Details>[0]['signals']}
				templating={templating}
				stylesheet={stylesheet}
				injected={{ ...injected, VerticalList, configurator, Modal, Navigable, Autoscrollable }}
			/>
			<Footer
				data={data}
				signals={signals as Parameters<Layouts.Details>[0]['signals']}
				templating={templating}
				stylesheet={stylesheet}
				injected={{ ...injected, NumberInput, Navigable }}
				inEdition={inEdition}
			/>
		</div>
	);
}) as Layouts.Details;
